import React, { useState } from 'react';

const faqData = [
  {
    question: "Do I automatically get an entry ticket to the Millennium Millionaire and/or Finest Surprise draw when I shop at Dubai Duty Free?",
    answer: "No, you must purchase the ticket separately to participate in the draw."
  },
  {
    question: "Can I refund/return the purchased Millennium Millionaire or Finest Surprise ticket before the draw date?",
    answer: "No, all ticket purchases are final and non-refundable."
  },
  {
    question: "What payment method do you accept online?",
    answer: "We accept major credit and debit cards for online purchases."
  },
  {
    question: "How are the Millennium Millionaire and Finest Surprise draws conducted?",
    answer: "Draws are conducted publicly at specific intervals and are overseen by officials."
  },
  {
    question: "Once I purchased a ticket, how do I know when the draw for my series takes place?",
    answer: "You will receive a notification with the details of the draw date after your purchase."
  },
];

const FAQ = () => {
  const [openIndex, setOpenIndex] = useState(null);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-md p-4 mx-auto">
      <h2 className="mb-4 text-lg font-bold">Frequently Asked Questions</h2>
      {faqData.map((faq, index) => (
        <div key={index} className="flex flex-col py-2 bg-[#EEEFF6] rounded-lg mb-2 p-2 border-b ">

          <div className="flex items-start">
            
          <svg className='flex-shrink-0 mr-2' width="27" height="34" viewBox="0 0 27 34" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_32_2)">
<g opacity="0.550112">
<path fill-rule="evenodd" clip-rule="evenodd" d="M23.8 33.84L14.64 27.52C14.3733 27.5467 13.96 27.56 13.4 27.56C10.6266 27.56 8.27334 26.9867 6.34 25.84C4.40665 24.6933 2.94667 23.1 1.96 21.06C0.973324 19.02 0.479996 16.6534 0.479996 13.96C0.479996 10.9733 1.07999 8.47336 2.28 6.46002C3.48 4.44667 5.07332 2.95336 7.06 1.98002C9.04667 1.00668 11.2533 0.52002 13.68 0.52002C16.4533 0.52002 18.8067 1.09335 20.74 2.24002C22.6733 3.38669 24.1267 4.98001 25.1 7.02002C26.0733 9.06003 26.56 11.4267 26.56 14.12C26.56 16.7334 26 19.02 24.88 20.98C23.76 22.94 22.2667 24.4933 20.4 25.64L26.92 28.96L23.8 33.84ZM6.32 13.92C6.32 16.7467 6.91999 18.9533 8.12 20.54C9.32 22.1267 11.08 22.92 13.4 22.92C14.9733 22.92 16.3133 22.5667 17.42 21.86C18.5267 21.1533 19.3533 20.14 19.9 18.82C20.4467 17.5 20.72 15.9467 20.72 14.16C20.72 11.3333 20.12 9.12669 18.92 7.54002C17.72 5.95335 15.9733 5.16002 13.68 5.16002C11.28 5.16002 9.45334 5.94001 8.2 7.50002C6.94666 9.06003 6.32 11.2 6.32 13.92Z" fill="#9B9B9B"/>
</g>
</g>
<defs>
<clipPath id="clip0_32_2">
<rect width="27" height="34" fill="white"/>
</clipPath>
</defs>
</svg>

          <div
            className="flex items-center justify-between cursor-pointer"
            onClick={() => toggleFAQ(index)}
          >
            <h3 className="text-sm font-semibold">{faq.question}</h3>
            <span className="flex-shrink-0 w-4 text-lg text-center">
              {openIndex === index ? '-' : '+'}
            </span>
          </div>
            </div>
          {openIndex === index && (
            <p className="flex mt-4 text-sm text-gray-600"><p className='text-2xl text-[#333] font-bold mr-2'>A:</p>{faq.answer}</p>
          )}
        </div>
      ))}

    </div>
  );
};

export default FAQ;
