import React from 'react'
import { Link } from 'react-router-dom'

const NavBar = () => {
  return (
    <div className='w-full flex  bg-[#2b2c2d] '>
      <Link to={'/'}>
        <img className='p-1 bg-white w-14 '  src="/assets/img/DDF Logo.png" alt="" />
      </Link>
    </div>
  )
}

export default NavBar