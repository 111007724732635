import React, { useState } from 'react';
import Select from 'react-select';
import Flag from 'react-world-flags';

const countries = [
  { code: 'AE', label: 'United Arab Emirates (الإمارات العربية المتحدة)', dial_code: '+971' },
  { code: 'BH', label: 'Bahrain (البحرين)', dial_code: '+973' },
  { code: 'EG', label: 'Egypt (مصر)', dial_code: '+20' },
  { code: 'KW', label: 'Kuwait (الكويت)', dial_code: '+965' },
  { code: 'LB', label: 'Lebanon (لبنان)', dial_code: '+961' },
  { code: 'OM', label: 'Oman (عمان)', dial_code: '+968' },
  { code: 'QA', label: 'Qatar (قطر)', dial_code: '+974' },
  { code: 'SA', label: 'Saudi Arabia (المملكة العربية السعودية)', dial_code: '+966' },
];

const CountryCodeForm = () => {
  const [country, setCountry] = useState({ code: 'AE', label: 'United Arab Emirates (الإمارات العربية المتحدة)', dial_code: '+971' });

  const handleCountryChange = (selectedOption) => {
    setCountry(selectedOption);
  };

  return (
    <div className="max-w-lg p-6 mx-auto text-[#4a4a4a] bg-white ">
        <div className="flex items-center mb-2">
          <span className="text-lg font-semibold text-[#4a4a4a]">DETAILS</span>
        </div>

      <form>
        <div className="mb-2">
          <label htmlFor="email" className="block mb-2 text-sm font-semibold">Email *</label>
          <input type="email" id="email" name="email" className="w-full p-2 text-[#4a4a4a] bg-transparent border rounded-none border-neutral-700 " required />
        </div>

        <div className="mb-2">
          <label htmlFor="first-name" className="block mb-2 text-sm font-semibold">First Name *</label>
          <input type="text" id="first-name" name="first-name" className="w-full p-2 text-[#4a4a4a] bg-transparent border rounded-none border-neutral-700 " required />
        </div>

        <div className="mb-2">
          <label htmlFor="surname" className="block mb-2 text-sm font-semibold">Surname *</label>
          <input type="text" id="surname" name="surname" className="w-full p-2 text-[#4a4a4a] bg-transparent border rounded-none border-neutral-700 " required />
        </div>

        <div className="mb-2">
          <label htmlFor="mobile" className="block mb-2 text-sm font-semibold">Mobile phone *</label>
          <div className="flex items-center">
            {/* Country code selector */}
            <div className="">
              <Select
                value={country}
                onChange={handleCountryChange}
                options={countries}
                formatOptionLabel={(e) => (
                  <div className="flex items-center">
                    <Flag code={e.code} alt={e.label} style={{ width: '30px', height: '20px', marginRight: '10px' }} />
                    <span>{e.dial_code}</span> {/* Show dial code with flag */}
                  </div>
                )}
                getSingleValue={(e) => (
                  <div className="flex items-center">
                    <Flag code={e.code} alt={e.label} style={{ width: '30px', height: '20px', marginRight: '10px' }} />
                    <span>{e.dial_code}</span> {/* Show dial code when selected */}
                  </div>
                )}
                placeholder="Select a country"
                className="flex-1 text-black"
                classNamePrefix="country-code-select"
                styles={{
                  control: (provided) => ({
                    ...provided,
                    backgroundColor: '#ffffff',
                    borderColor: '#444',
                    color: 'white',
                    padding: '5px',
                    borderRadius: '4px',
                    width: '100px',  // Set width to 100% for larger width
                  }),
                  menu: (provided) => ({
                    ...provided,
                    backgroundColor: '#ffffff',
                    color: 'black',
                  }),
                  option: (provided) => ({
                    ...provided,
                    backgroundColor: '#ffffff',
                    color: 'black',
                    padding: '10px',
                  }),
                }}
              />
            </div>
            {/* Mobile input */}
            <input
              type="tel"
              id="mobile"
              name="mobile"
              className="w-full p-2 ml-2 text-[#4a4a4a] bg-transparent border rounded-none border-neutral-700"

              required
            />
          </div>
        </div>

     </form>
    </div>
  );
};

export default CountryCodeForm;
