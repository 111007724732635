import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import trackUserAction from '../../providers/track_action';

const TicketSelection = ({series, totalAvailable, id}) => {
  const ticketRanges = [
    { label: '< 1000', start: 1, end: 1000 },
    { label: '1001-2000', start: 1001, end: 2000 },
    { label: '2001-3000', start: 2001, end: 3000 },
    { label: '3001-4000', start: 3001, end: 4000 },
    { label: '> 4000', start: 4001, end: 5000 },
  ];


  const totalTickets = ticketRanges.reduce((sum, range) => sum + (range.end - range.start + 1), 0);
  const availabilityRatio = totalAvailable / totalTickets; // Ratio to determine skipping

  const defaultRange = ticketRanges[0];

  const [selectedRange, setSelectedRange] = useState(defaultRange);
  const [tickets, setTickets] = useState([]);
  const [selectedTickets, setSelectedTickets] = useState([]); // State for selected tickets
  const [currentPage, setCurrentPage] = useState(1);
  const ticketsPerPage = 100;

  const generateAvailableTickets = (start, end) => {
    const ticketsInRange = Array.from({ length: end - start + 1 }, (_, i) => start + i);
    return ticketsInRange.filter(() => Math.random() < availabilityRatio);
  };

  const getStaticAvailableTickets = (series) => {
    const storedData = localStorage.getItem('availableTickets');
    
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      
      // Check if the series in localStorage matches the requested series
      if (parsedData.series === series) {
        return parsedData.tickets;
      }
    }
    
    // Generate new tickets if there's no match or no stored data
    const generatedTickets = ticketRanges.reduce((acc, range) => {
      acc[range.label] = generateAvailableTickets(range.start, range.end);
      return acc;
    }, {});
  
    // Store the tickets along with the series in localStorage
    const dataToStore = { series, tickets: generatedTickets };
    localStorage.setItem('availableTickets', JSON.stringify(dataToStore));
    
    return generatedTickets;
  };
  

  const [availableTickets, setAvailableTickets] = useState(getStaticAvailableTickets(series));

  useEffect(() => {
    setTickets(availableTickets[defaultRange.label] || []);
  }, [availableTickets, defaultRange.label]);

  const handleRangeSelect = (range) => {
    setSelectedRange(range);
    setCurrentPage(1);
    setTickets(availableTickets[range.label] || []);
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  // Toggle ticket selection
  const toggleTicketSelection = (ticketNumber) => {
    trackUserAction(`User chose ${ticketNumber} number`, false)
    setSelectedTickets((prevSelected) =>
      prevSelected.includes(ticketNumber)
        ? prevSelected.filter((num) => num !== ticketNumber) // Deselect if already selected
        : [...prevSelected, ticketNumber] // Select if not selected
    );
  };

  const startIndex = (currentPage - 1) * ticketsPerPage;
  const displayedTickets = tickets.slice(startIndex, startIndex + ticketsPerPage);

  return (
    <div className="max-w-lg p-4 mx-auto bg-[#EEEFF6]">
      <h2 className="mb-4 text-lg font-bold text-center">SELECT FROM AVAILABLE TICKETS</h2>

      {/* Range Buttons */}
      <div className="flex flex-wrap justify-center gap-2 mb-6 space-x-4 ">
        {ticketRanges.map((range) => (
          <button
            key={range.label}
            onClick={() => handleRangeSelect(range)}
            className={`cusbutton leading-3`}
          >
            {range.label}
          </button>
        ))}

      </div>

      {/* Search Bar */}
      {/* <div className="flex items-center justify-center mb-4 space-x-2">
        <label htmlFor="search" className="text-sm">SEARCH</label>
        <input
          type="text"
          id="search"
          placeholder="Enter ticket number"
          className="p-2 border rounded-md focus:outline-none"
        />
        <button className="px-4 py-2 text-white bg-gray-800 rounded-md hover:bg-gray-700">GO</button>
      </div> */}

      {/* Ticket Grid */}
      <div className="grid grid-cols-5 gap-2 mb-4">
        {displayedTickets.map((ticketNumber) => (
          <button
            key={ticketNumber}
            onClick={() => toggleTicketSelection(ticketNumber)}
            className={`border-2 rounded-sm py-1 leading-4  text-[10px] text-center ${
              selectedTickets.includes(ticketNumber) ? 'bg-green-500 text-white' : 'bg-white text-[#808080]'
            }`}
          >
            {series} <br /> <span className='text-[14px] '>{ticketNumber}</span>
          </button>
        ))}
      </div>

      {/* Pagination Controls */}
      {tickets.length > ticketsPerPage && (
        <div className="flex justify-center mb-4 space-x-4">
          {[...Array(Math.ceil(tickets.length / ticketsPerPage)).keys()].map((page) => (
            <button
              key={page + 1}
              onClick={() => handlePageChange(page + 1)}
              className={`px-4 py-2 rounded-sm border border-neutral-500 ${currentPage === page + 1 ? 'bg-[#eaeaea] ' : 'bg-white'} `}
            >
              {page + 1}
            </button>
          ))}
        </div>
      )}

      {/* Display Selected Tickets */}
      <div className="p-4 mt-4 bg-white rounded-b-md border-t-4 border-[#9b9b9b]">
        <h3 className="mb-2 text-xl font-bold text-center">YOUR SELECTION</h3>
        {selectedTickets.length > 0 ? (
          <div className="flex flex-wrap gap-2">
            {selectedTickets.map((ticket) => (
              <span
                key={ticket}
                className="flex flex-col text-center px-3 py-1 text-sm border border-neutral-900  bg-[#e5fbcf] rounded-md"
              >
                <span className='text-[#808080]'>{series}</span>
                <span className='text-[15px]'>{ticket}</span>
                 
              </span>
            ))}
          </div>
        ) : (
          <p className="text-gray-500">No tickets selected.</p>
        )}
        <div className="w-full border-2 border-dashed border-[#eeeff6] mt-4"></div>
        <div className="w-full border-2 border-dashed border-[#eeeff6] mt-[2px]"></div>

        <div className="flex flex-col mt-4">

        <span className='text-sm uppercase text-[#808080]'>total price for your tickets</span>
        <span className='font-semibold text-black'>{(selectedTickets.length*600).toFixed(2)} <span className='text-[#808080] font-normal'>AED</span></span>
        </div>

        <div className="w-full border-2 border-dashed border-[#eeeff6] mt-4"></div>
        <div className="w-full border-2 border-dashed border-[#eeeff6] mt-[2px]"></div>
        
        <div className="flex justify-center w-full mt-4">

        <Link onClick={()=>trackUserAction(`User went to checkout`, false)} to={`/cart?items=${selectedTickets.join(',')}&num=${series}&id=${id}`} className="bg-[#06c5be] p-2 px-4 text-lg text-white rounded font-semibold uppercase">
          PROCEED TO CHECKOUT
        </Link>
        </div>



      </div>
    </div>
  );
};

export default TicketSelection;
