import React from 'react';
import { FaCcAmex, FaCcMastercard, FaCcVisa } from 'react-icons/fa';
import { MdVerified } from 'react-icons/md';
import { RiSecurePaymentLine } from 'react-icons/ri';

const Footer = () => {
  return (
    <footer className="bg-black text-white py-6">
      {/* Payment Icons */}
      <div className="flex justify-center space-x-6 mb-4 text-2xl">
        <FaCcAmex title="American Express" />
        <FaCcMastercard title="Mastercard" />
        <FaCcVisa title="Visa" />
        <MdVerified title="Verified by Visa" />
        <RiSecurePaymentLine title="MasterCard SecureCode" />
      </div>

      {/* Copyright */}
      <div className="text-center text-sm mb-4">
        ©2024 Dubai Duty Free. All Rights Reserved.
      </div>

      {/* Footer Links */}
      <div className="text-center text-sm grid grid-cols-2 md:grid-cols-4 gap-2">
        <a href="#" className="hover:underline">Privacy Policy</a>
        <a href="#" className="hover:underline">Terms and Conditions</a>
        <a href="#" className="hover:underline">Site Map</a>
        <a href="#" className="hover:underline">Cookie Policy</a>
      </div>
    </footer>
  );
};

export default Footer;
