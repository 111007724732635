import React, { useState } from 'react';

const NewsletterForm = () => {
  const [email, setEmail] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Email submitted:", email);
    // Add form submission logic here
  };

  return (
    <div className="bg-[#666666] p-6 w-full mx-auto text-center">
      <h2 className="mb-4 text-lg font-semibold text-white">
        Subscribe to DDF Newsletter
      </h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Your Email"
          className="w-full p-2 rounded-md focus:outline-none"
        />
        <button
          type="submit"
          className="w-full p-2 font-semibold text-white bg-black rounded-md hover:bg-gray-800"
        >
          SUBSCRIBE
        </button>
      </form>
    </div>
  );
};

export default NewsletterForm;
