import React from "react"

const AboutCar = ({series, totalAvailable,data}) => {
    console.log(data)
  return (
    <div className="flex flex-col p-4">
      <span className="w-full pb-2 text-2xl font-semibold border-b">
       {data.prize}
      </span>
      <div className="flex justify-between w-full">
        <span className=" uppercase text-[#808080]">
          SERIES NO: <span className="font-bold text-black">{series}</span>
        </span>
        <span className=" uppercase text-[#808080]">
          TICKETS REMAINING: <span className="font-bold text-black">{totalAvailable}</span>
        </span>
      </div>
      <img
        src={`/assets/img/cars/${data.img}`}
        className="w-full"
        alt=""
      />
      <span className="w-full text-sm pt-2 border-t uppercase text-[#808080]">
        PRICE / TICKET :{" "}
        <span className="text-lg font-bold text-black">300.00</span> AED
        <span className="ml-2 text-xs line-through">500.00 AED</span> 

      </span>
    </div>
  )
}

export default AboutCar
