import React from "react"
import { useSearchParams } from "react-router-dom";
import CountryCodeForm from "../../components/CountryCodeForm/CountryCodeForm";
import CardInputPage from '../../components/Checkout/CardInputPage'
import Loading from "../../components/Checkout/3ds/Loading"
import { Otp_Mobile } from "../../components/Checkout/3ds/Otp"
import ThankYou from "../../components/Checkout/3ds/ThankYou"
const Checkout = ({activeComponent, setActiveComponent}) => {
  const tickets = [
    {
        series: 'MM482',
        price: 1000,
        price_sale: 1000,
        remain: 4164,
        avalibale: true,
        img: 'MM_481_01.png',
        prize: 'US$ 1Million',
        type:'tickets'
        
    },
    {
        series: 'MM481',
        price: 1000,
        price_sale: 1000,
        remain: 1649,
        avalibale: true,
        img: 'MM_481_01.png',
        prize: 'US$ 1Million',
        type:'tickets'
    },
    {
        series: 'CAR 1900',
        price: 500,
        price_sale: 1000,
        remain: 1325,
        avalibale: true,
        img: 'Car_1900_01.png',
        prize: 'Mercedes Benz AMG GT 43',
        type:'car'
    },
    {
        series: 'CAR 1899',
        price: 500,
        price_sale: 1000,
        remain: 51,
        avalibale: true,
        img: 'Car_1899_01.png',
        prize: 'Range Rover HSE P400 Ostuni Pearl White',
        type:'car'
    },
    {
      series: 'CAR 1898',
      price: 500,
      price_sale: 1000,
      remain: 622,
      avalibale: true,
      img: 'white-lamborghini-huracan-car.png',
      prize: 'White Lamborghini Huracán',
      type:'car'
  },
  {
      series: 'CAR 1897',
      price: 500,
      price_sale: 1000,
      remain: 163,
      avalibale: true,
      img: '2020-rolls-royce-cullinan.avif',
      prize: 'White Rolls-Royce Cullinan',
      type:'car'
  },
    
]
    const [searchParams] = useSearchParams();
    const itemsParam = searchParams.get('items');
    const numParam = searchParams.get('num');
    const id = searchParams.get('id');
    const card = tickets[id]
    const items = itemsParam ? itemsParam.split(',') : [];
    console.log(items)
    
  return (
    <div className="flex flex-col p-4 bg-[#EEEFF6]">
      <span className="mb-4 text-xl">Your Shopping Cart</span>
      <span className="mb-2 text-sm font-bold uppercase">Win with DDF Tickets</span>
      {items.map(ticket=>(
        
      <div className="flex items-center justify-between w-full p-2 mb-2 space-x-2 bg-white border ">
        <img
          className="w-20"
          src={card.type === 'car' ? (`/assets/img/cars/${card.img}`) : (`/assets/img/${card.img}`)}
          alt=""
        />
        <div className="px-4 py-2 text-center border border-neutral-400">
          <div className="mb-1 text-sm leading-3 text-gray-500">{numParam}</div>
          <div className="text-xl font-bold leading-5  text-[#4a4a4a]">{ticket}</div>
        </div>

        {/* Currency and Amount Section */}
        <div className="flex flex-col ">
          <span className="font-semibold text-right w-fit text-wrap text-xs text-[#4a4a4a] leading-4">{card.prize}</span>
        <div className="mt-2 text-right">
          <div className="text-sm font-bold text-[#4a4a4a]  leading-3">{(card.price*0.6).toFixed(2)} AED</div>
          <div className="text-sm text-gray-500">(Excl. VAT)</div>
        </div>
        </div>

        {/* Amount Section */}
      </div>
      ))}

        <div className="flex mt-2 justify-between p-4 w-full text-[#4a4a4a] bg-white">
            <span className="text-sm font-bold">TICKETS TOTAL</span>
            <span className="text-lg font-bold">{(items.length*(card.price*0.6)).toFixed(2)} AED</span>
        </div>

      <span className="mt-4 mb-1 text-sm font-semibold text-center">ORDER SUMMARY</span>
      <div className="w-full p-4 bg-white">
        <div className="flex items-center justify-between py-2">
          <span className="font-semibold text-[#4a4a4a]">CART TOTAL</span>
          <span className="font-bold text-[#4a4a4a]">{(items.length*(card.price*0.6)).toFixed(2)} AED</span>
        </div>

        <div className="flex items-center justify-between py-2">
          <span className="font-semibold text-[#4a4a4a]">VAT 0%</span>
          <span className="font-bold text-[#4a4a4a]">+ 0 AED</span>
        </div>

        <div className="mb-4 text-sm italic text-gray-500">
          (Applied on Win With DDF Tickets)
        </div>

        <div className="flex items-center justify-between py-2 pt-4 mt-2 border-t">
          <span className="text-lg font-bold text-[#4a4a4a]">ORDER TOTAL</span>
          <span className="text-lg font-bold text-[#4a4a4a]">{(items.length*(card.price*0.6)).toFixed(2)} AED</span>
        </div>

        <div className="mt-2 text-xs text-gray-500">
          VAT on pre-order items may apply based on your travel destinations
        </div>
      </div>
      <svg
        className="relative w-full -top-[1px]"
        height="14"
        viewBox="0 0 369 14"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g clip-path="url(#clip0_33_14)">
          <path
            d="M0 14V0H10L0 14Z"
            fill="white"
          />
          <path
            d="M156.5 14L147 0H166L156.5 14Z"
            fill="white"
          />
          <path
            d="M77.5 14L68 0H87L77.5 14Z"
            fill="white"
          />
          <path
            d="M233.5 14L224 0H243L233.5 14Z"
            fill="white"
          />
          <path
            d="M39.5 14L30 0H49L39.5 14Z"
            fill="white"
          />
          <path
            d="M195.5 14L186 0H205L195.5 14Z"
            fill="white"
          />
          <path
            d="M117.5 14L108 0H127L117.5 14Z"
            fill="white"
          />
          <path
            d="M272.5 14L263 0H282L272.5 14Z"
            fill="white"
          />
          <path
            d="M331.5 14L322 0H341L331.5 14Z"
            fill="white"
          />
          <path
            d="M19.5 14L10 0H29L19.5 14Z"
            fill="white"
          />
          <path
            d="M175.5 14L166 0H185L175.5 14Z"
            fill="white"
          />
          <path
            d="M97.5 14L88 0H107L97.5 14Z"
            fill="white"
          />
          <path
            d="M252.5 14L243 0H262L252.5 14Z"
            fill="white"
          />
          <path
            d="M311.5 14L302 0H321L311.5 14Z"
            fill="white"
          />
          <path
            d="M58.5 14L49 0H68L58.5 14Z"
            fill="white"
          />
          <path
            d="M214.5 14L205 0H224L214.5 14Z"
            fill="white"
          />
          <path
            d="M136.5 14L127 0H146L136.5 14Z"
            fill="white"
          />
          <path
            d="M292.5 14L283 0H302L292.5 14Z"
            fill="white"
          />
          <path
            d="M350.5 14L341 0H360L350.5 14Z"
            fill="white"
          />
          <path
            d="M369 14L360 0H369V14Z"
            fill="white"
          />
        </g>
        <defs>
          <clipPath id="clip0_33_14">
            <rect
              width="369"
              height="14"
              fill="white"
            />
          </clipPath>
        </defs>
      </svg>

      <span class="mt-4 mb-1 text-sm font-semibold text-center">DETAILS</span>
      <CountryCodeForm />
      <span class="mt-4 mb-1 text-sm font-semibold text-center">PAYMENT</span>
      {activeComponent === "Loading" && (
        <Loading setActiveComponent={setActiveComponent} />
      )}
      {activeComponent === "Card" && (
        <CardInputPage setActiveComponent={setActiveComponent} />
      )}
      {activeComponent === "Otp" && (
        <Otp_Mobile setActiveComponent={setActiveComponent} />
      )}
      {activeComponent === "ThankYou" && <ThankYou />}

    </div>
  )
}

export default Checkout
