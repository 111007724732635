import React from "react"
import { Link } from "react-router-dom"
import trackUserAction from "../../providers/track_action"

const BuyTickets = () => {
    const tickets = [
        {
            series: 'MM482',
            price: 1000,
            price_sale: 1000,
            remain: 4164,
            avalibale: true,
            img: 'MM_481_01.png',
            prize: 'US$ 1Million',
            type:'tickets'
            
        },
        {
            series: 'MM481',
            price: 1000,
            price_sale: 1000,
            remain: 1649,
            avalibale: true,
            img: 'MM_481_01.png',
            prize: 'US$ 1Million',
            type:'tickets'
        },
        {
            series: 'CAR 1900',
            price: 500,
            price_sale: 1000,
            remain: 1325,
            avalibale: true,
            img: 'Car_1900_01.png',
            prize: 'Mercedes Benz AMG GT 43',
            type:'car'
        },
        {
            series: 'CAR 1899',
            price: 500,
            price_sale: 1000,
            remain: 51,
            avalibale: true,
            img: 'Car_1899_01.png',
            prize: 'Range Rover HSE P400 Ostuni Pearl White',
            type:'car'
        },
        {
            series: 'CAR 1898',
            price: 500,
            price_sale: 1000,
            remain: 622,
            avalibale: true,
            img: 'white-lamborghini-huracan-car.png',
            prize: 'White Lamborghini Huracán',
            type:'car'
        },
        {
            series: 'CAR 1897',
            price: 500,
            price_sale: 1000,
            remain: 163,
            avalibale: true,
            img: '2020-rolls-royce-cullinan.avif',
            prize: 'White Rolls-Royce Cullinan',
            type:'car'
        },
    ]
  return (
    <div className="p-4">
      <span className="text-xl">Buy Tickets</span>
      <div className="grid grid-cols-2 gap-2 ">
        {tickets.map((ticket, index)=>(

        <Link
        onClick={()=>trackUserAction(`User clicked ${ticket.prize}`, false)}
          className="relative flex flex-col bg-white shadow "
          to={`/${ticket.type}/${ticket.series}/${ticket.remain}/${index}`}
        >
          <strong data-bind="text:flag" className="ticket-available-green">Available</strong>
          <img
            className={ticket.type === 'car' ? (`w-full pt-10`) : (`w-full`)}
            src={ticket.type === 'car' ? (`/assets/img/cars/${ticket.img}`) : (`/assets/img/${ticket.img}`)}
            alt=""
          />
          <div className="flex flex-col p-2 pb-10 gap-y-2">
            <span className=" text-xs font-bold text-[#b9b9b9]">
              SERIES NO: {ticket.series}
            </span>
            <span className="text-xs font-bold text-black ">{ticket.prize}</span>
            <span className=" text-xs  text-[#b9b9b9]">
              TICKET PRICE
            </span>
            <span className="text-xs text-black line-through ">
            {ticket.price.toFixed(2)}{" "}
              <span className=" text-xs  text-[#b9b9b9]">AED</span>
            </span>
            <span className="text-sm font-bold text-black ">
            {(ticket.price*0.6).toFixed(2)}{" "}
              <span className="text-xs ">AED</span>
            </span>

            <div className="flex items-center">
              <div className="p-1 text-white bg-[#5aac00] font-bold">{ticket.remain}</div>
              <span className=" text-[8px]  w-1/2 ml-2">TICKETS REMAINING</span>
            </div>
          </div>
        </Link>
        ))}
      </div>
    </div>
  )
}

export default BuyTickets
