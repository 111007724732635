import React from 'react'
import TicketSelection from '../../components/TicketSelect'
import FAQ from '../../components/Faq'
import NewsletterForm from '../../components/NewsletterForm'
import AboutTicket from '../../components/AboutTicket'
import { useParams } from 'react-router-dom'
const Tickets = () => {
  const {series, left, id} = useParams()
  return (
    <div>
        <AboutTicket series={series} totalAvailable={left} />
            <TicketSelection series={series} totalAvailable={left} id={id} />
            <div className="w-full p-4">
              <span className='text-sm'>NOTE:Tickets added to the cart will be on hold for 20 minutes after which they will become
              available for purchase by other customers.</span>
            </div>
            <FAQ />
            <NewsletterForm />
    </div>
  )
}

export default Tickets