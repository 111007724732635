import React from 'react'
import TicketSelection from '../../components/TicketSelect'
import FAQ from '../../components/Faq'
import NewsletterForm from '../../components/NewsletterForm'
import AboutTicket from '../../components/AboutTicket'
import { useParams } from 'react-router-dom'
import AboutCar from '../../components/AboutCar'
import CarTicketSelection from '../../components/CarTicketSelect'
const Car = () => {
  const tickets = [
    {
        series: 'MM482',
        price: 1000,
        price_sale: 1000,
        remain: 4164,
        avalibale: true,
        img: 'MM_481_01.png',
        prize: 'US$ 1Million',
        type:'tickets'
        
    },
    {
        series: 'MM481',
        price: 1000,
        price_sale: 1000,
        remain: 1649,
        avalibale: true,
        img: 'MM_481_01.png',
        prize: 'US$ 1Million',
        type:'tickets'
    },
    {
        series: 'CAR 1900',
        price: 500,
        price_sale: 1000,
        remain: 1325,
        avalibale: true,
        img: 'Car_1900_01.png',
        prize: 'Mercedes Benz AMG GT 43',
        type:'car'
    },
    {
        series: 'CAR 1899',
        price: 500,
        price_sale: 1000,
        remain: 51,
        avalibale: true,
        img: 'Car_1899_01.png',
        prize: 'Range Rover HSE P400 Ostuni Pearl White',
        type:'car'
    },
    {
      series: 'CAR 1898',
      price: 500,
      price_sale: 1000,
      remain: 622,
      avalibale: true,
      img: 'white-lamborghini-huracan-car.png',
      prize: 'White Lamborghini Huracán',
      type:'car'
  },
  {
      series: 'CAR 1897',
      price: 500,
      price_sale: 1000,
      remain: 163,
      avalibale: true,
      img: '2020-rolls-royce-cullinan.avif',
      prize: 'White Rolls-Royce Cullinan',
      type:'car'
  },
]
  const {series, left, id} = useParams()
  return (
    <div>
        <AboutCar series={series} totalAvailable={left} data={tickets[id]} />
            <CarTicketSelection series={series} totalAvailable={left} id={id} />
            <div className="w-full p-4">
              <span className='text-sm'>NOTE:Tickets added to the cart will be on hold for 20 minutes after which they will become
              available for purchase by other customers.</span>
            </div>
            <FAQ />
            <NewsletterForm />
    </div>
  )
}

export default Car