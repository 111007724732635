import React from 'react';
import { FaTicketAlt } from 'react-icons/fa';
import { MdEmail } from 'react-icons/md';

const ThankYou = () => {
  return (
    <div className="w-full p-4">

    <div className="flex items-center justify-center h-[32rem] p-6 my-6 rounded-xl bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500">
      <div className="max-w-lg p-10 text-center bg-white shadow-xl rounded-2xl">
        <FaTicketAlt className="mx-auto mb-6 text-6xl text-pink-500" />
        <h1 className="mb-4 font-bold text-gray-800">Thank You for Your Purchase!</h1>
        <p className="mb-4 text-sm text-gray-600">
          Your tickets are on the way! Keep an eye on your inbox.
        </p>
        <div className="inline-flex items-center justify-center p-4 mb-6 rounded-lg bg-indigo-50">
          <MdEmail className="mr-2 text-2xl text-indigo-600" />
          <span className="text-xs font-medium text-indigo-700">Check your email for more info.</span>
        </div>

    </div>
      </div>
    </div>
  );
};

export default ThankYou;
