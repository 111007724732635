import React, { useEffect } from 'react'
import HeroSection from '../../components/HeroSection'
import BuyTickets from '../../components/BuyTickets'
import RecentWinners from '../../components/RecentWinners'
import FAQ from '../../components/Faq'
import NewsletterForm from '../../components/NewsletterForm'
import { useParams } from 'react-router-dom'
import trackUserAction from '../../providers/track_action'

const Main = () => {
  useEffect(()=>{
    trackUserAction('User landed on page', false)
  },[])
  return (
    <div className='bg-[#EEEFF6] flex flex-col '>
        <HeroSection />
        <BuyTickets  />
        <RecentWinners />
        <FAQ />
        <NewsletterForm />
    </div>
  )
}

export default Main