import React from 'react'

const RecentWinners = () => {

    const lotto_winners = [
        {
            series: 'MM478',
            name: 'Khalid Pervez',
            tick_no: 4571,
            draw_date: '30/10/2024',
            nation: 'Pakistan',
            bought: 'In-Store',
            img: 'MM_MM478_01.png'
        },
        {
            series: 'MM477',
            name: 'Amit Saraf',
            tick_no: 2813,
            draw_date: '23/10/2024',
            nation: 'India',
            bought: 'Online',
            img: 'MM_MM477_01.png'
        },
        {
            series: 'MM476',
            name: 'Ali Akram Arabo',
            tick_no: 3893,
            draw_date: '09/10/2024',
            nation: 'Syrian Arab Republic',
            bought: 'Online',
            img: 'MM476-1.png'
        },
        {
            series: 'MM475',
            name: 'Abdullah Alsayed',
            tick_no: 300,
            draw_date: '25/09/2024',
            nation: 'Saudi Arabia',
            bought: 'Online',
            img: 'MM_MM474_01.png'
        },
        {
            series: 'MM474',
            name: 'Kyriacos Michaelides',
            tick_no: 2755,
            draw_date: '25/09/2024',
            nation: 'Cyprus',
            bought: 'In-Store',
            img: 'MM_MM474_01.png'
        },
        {
            series: 'MM473',
            name: 'Naseer Arikkoth',
            tick_no: 1617,
            draw_date: '11/09/2024',
            nation: 'India',
            bought: 'Online',
            img: 'MM471.avif'
        },
        {
            series: 'MM472',
            name: 'Abdul Azeez',
            tick_no: 3361,
            draw_date: '11/09/2024',
            nation: 'India',
            bought: 'Online',
            img: 'MM471.avif'
        },
        {
            series: 'MM471',
            name: 'Asif Mathilakath Assees',
            tick_no: 4909,
            draw_date: '28/08/2024',
            nation: 'Indian',
            bought: 'In-Store',
            img: 'MM471.avif'
        },
        {
            series: 'MM470',
            name: 'Hisham Alshelh',
            tick_no: 4481,
            draw_date: '07/08/2024',
            nation: 'Canadian',
            bought: 'In-Store',
            img: 'MM470_Winner.png'
        },
        {
            series: 'MM469',
            name: 'Saeed Mohamad Yousuf',
            tick_no: 4399,
            draw_date: '24/07/2024',
            nation: 'Emirati',
            bought: 'In-Store',
            img: 'MM469_Winner.png'
        },
    ]

  return (
    <div className='p-4'>
        <span className="mb-2 text-xl">Recent Winners</span>
        <div className="flex w-full overflow-x-scroll gap-x-2">
            {lotto_winners.map(winner=>(
                <div className="flex flex-col flex-shrink-0 w-1/2 shadow">
                    <img src={`/assets/img/winners/${winner.img}`} className='w-full' alt="" />
                    <div className="w-full p-2 bg-white">
                    <span class=" text-xs font-bold text-[#b9b9b9]">SERIES NO: {winner.series}</span>
                    </div>
                    <div className="w-full bg-[#e5fbcf] p-2 flex flex-col gap-y-2">
                        <span className='text-xs font-bold'>{winner.name}</span>
                        <div className="flex w-full text-xs">
                            <span className='w-1/2 text-[#4a4a4a]'>Ticket No</span>
                            <span className='w-1/2 font-bold text-black'>{winner.tick_no}</span>
                        </div>
                        <div className="flex w-full text-xs">
                            <span className='w-1/2 text-[#4a4a4a]'>Draw Date</span>
                            <span className='w-1/2 font-bold text-black'>{winner.draw_date}</span>
                        </div>
                        <div className="flex w-full text-xs">
                            <span className='w-1/2 text-[#4a4a4a]'>Nationality</span>
                            <span className='w-1/2 font-bold text-black'>{winner.nation}</span>
                        </div>
                        <div className="flex w-full text-xs">
                            <span className='w-1/2 text-[#4a4a4a]'>Bought</span>
                            <span className='w-1/2 font-bold text-black'>{winner.bought}</span>
                        </div>
                    </div>
                </div>
            ))}
        </div>
    </div>
  )
}

export default RecentWinners