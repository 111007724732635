import React from 'react'

const HeroSection = () => {
  return (
    <div className='w-full relative flex items-center justify-center'>
        <img className='h-48 object-cover' src="/assets/img/plp-banner-mm-02-2019.avif" alt="" />
        <div className="absolute top-0 left-0 bottom-0 right-0 bg-black bg-opacity-60 p-4 flex flex-col items-center">
            <div className="w-full text-white">
                <span className='font-semibold text-sm'>
                    Home / <span className=' font-normal'>Millennium Millionaire</span>
                </span>
            </div>
            <div className="flex space-y-4 mt-2 flex-col text-center text-white">
                <span className='text-xl font-semibold'>Millennium Millionaire</span>
                <span className='text-xs'>Only one-in-5,000 chances to win US$1 Million with Dubai Duty Free.</span>
                <span className='text-xs'>There have been 8 lucky ticket holders who have won the promotion twice.</span>
            </div>
        </div>
    </div>
  )
}

export default HeroSection